import axios  from "axios";
import {ElLoading} from "element-plus";

const  requ =axios.create({
    baseURL:"http://8.134.145.28:9090/",
    timeout:5000000
})
let  loading
requ.interceptors.request.use(config=>{
    let  token=localStorage.getItem("token")
    loading=ElLoading.service({
        lock: true,
        text: '服务器酱在努力运算中。。。。。',
        background: 'rgba(0, 0, 0, 0.7)',
    })
    config.headers.token=token
    return config
}

)
requ.interceptors.response.use(res=>{
    loading.close()
    console.log(res)
    return res
},error => {
   if (error.request.status==401){
         localStorage.removeItem("token")
       location.reload()

   }
})

export  default  requ