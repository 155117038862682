import {createRouter, createWebHashHistory} from 'vue-router'


const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import("../views/index.vue")
    }, {
        path: '/signin',
        name: 'signin',
        component: () => import("../views/signin.vue")
    },
    {
        path: '/chat',
        name: "chat",
        component: () => import("../views/chat.vue")
    },
    {
        path: '/message',
        name: "message",
        component: () => import("../views/message.vue"),

    }, {
        path: "/friend",
        name: "/friend",
        component: () => import("../views/friend.vue")
    }, {

        path: "/chatinfo/:chatname",
        name: "chatinfo",
        component: () => import("../views/chatinfo.vue"),

    },
    {
        path: "/bx",
        name: "bx",
        component: () => import("../views/manage/bx.vue")
    },
    {
        path: "/jt",
        name: "jt",
        component: () => import("../views/manage/jt.vue")
    },
    {
        path: "/kc",
        name: "kc",
        component: () => import("../views/manage/kc.vue")
    },
    {
        path: "/qd",
        name: "qd",
        component: () => import("../views/manage/qd.vue")
    }
    ,
    {
        path: "/xs",
        name: "xs",
        component: () => import("../views/manage/xs.vue")
    }
    , {
        path: "/xw",
        name: "xw",
        component: () => import("../views/manage/xw.vue")
    }, {
        path: "/login",
        name: "login",
        component: () => import("../views/login.vue")
    }, {
    path:  "/superadmin",
        name: "superadmin",
        component:()=>import("../views/superadmin.vue")
    }


]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})
router.beforeEach((to, from, next) => {
    let token = localStorage.getItem("token")
    let username=localStorage.getItem("username")
    let togo = to.fullPath
    if (togo === "/login") {
        next()
    } else {
        if (token !== null) {
            if (togo==="/superadmin"){
                if (username!==null && username==="superadmin"){
                    next()
                }

            }else {
                next()
            }

        } else {
            next("/login")
        }
    }
})

export default router
