let ws = Date
ws.connect = function () {
    let url  =localStorage.getItem("wsurl")
     ws=new WebSocket(url)
    ws.onopen = function () {
        console.log("连接成功")
    }
    ws.onerror = function (error) {
        console.log("error", error)
    }
    ws.onclose = function () {
        console.log("连接关闭")
    }

}

ws.send = function (data) {
    if (ws.readyState == WebSocket.OPEN) {
        ws.send(data)
    } else {
        ws.connect()
    }
}

    ws.receive = function (callback) {

    ws.onmessage = function (event) {

        const data = JSON.parse(event.data)

        callback(data)
    }
}


ws.close = function () {

    if (ws.readyState === WebSocket.OPEN) {

        ws.close()
    }
}


export  default  ws