<template>
  <div>

    <div style="display: flex;height: 98vh">
      <div v-if="ishow==true" style="width: 12%">
        <el-menu class="el-menu-vertical-demo" mode="vertical" default-active="/" router>
          <el-menu-item index="/">ai教学助手</el-menu-item>
          <el-menu-item index="/signin">签到</el-menu-item>
          <el-menu-item index="/message">消息</el-menu-item>
          <el-menu-item index="/friend">我的好友</el-menu-item>
          <el-sub-menu>
            <template #title>
              <span style="font-size: 10px">教师工作台</span>
            </template>
            <el-menu-item index="/kc">课程管理</el-menu-item>
            <el-menu-item index="/jt">假条管理</el-menu-item>
            <el-menu-item index="/bx">报修管理</el-menu-item>
            <el-menu-item index="/qd">签到管理</el-menu-item>
            <el-menu-item index="/xw">新闻管理</el-menu-item>
            <el-menu-item index="/xs">学生管理</el-menu-item>
          </el-sub-menu>
          <el-menu-item  v-if="issuperadmin" index="/superadmin">超级管理员工作台</el-menu-item>
        </el-menu>
      </div>

      <div style="width: 88%">
        <div v-if="ishow==true"
             style="width: 100%;height: 6vh; border-bottom: #d9d8d8 solid 2px;background-color: #ececec;background-size: cover">
          <div style="float: right;margin-right: 80px;">
            <el-dropdown>
              <div style="display: flex;align-items: center">
                <el-image style="width: 100%;height: 100%;border-radius: 25px; height: 50px;width: 50px"
                          :src="url+'file/download?uuid='+userimae"/>

                <span style="margin-left: 5px">{{ username }}</span>
              </div>
              <arrow-down/>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>
                    <el-upload :auto-upload="false"
                               ref="upload"
                               :file-list="filelist"
                               :on-change="change"
                               :http-request="httprequ"

                    >
                      更改头像
                    </el-upload>
                  </el-dropdown-item>
                  <el-dropdown-item @click="updatepassword">修改密码</el-dropdown-item>
                  <el-dropdown-item @click="quit" style="color: red">退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>

          </div>
        </div>

        <router-view id="view" style="height: 94vh"/>
      </div>
    </div>
    <el-dialog
        v-model="dialogVisible"
        title="更改密码"
        width="30%"
    >
      <el-form>
        <span>密码</span>
        <el-input v-model="password" placeholder="请输入密码"/>
        <span>新密码</span>
        <el-input v-model="newpassword" placeholder="请输入新密码"/>
        <span>再输入一次</span>
        <el-input v-model="codepassword" placeholder="请再输入一次"/>


      </el-form>
      <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="qr">
          确认
        </el-button>
      </span>
      </template>
    </el-dialog>


  </div>

</template>

<script>


import requ from "@/util/requ";
import {ElMessage, ElMessageBox} from "element-plus";

export default {
  name: "Menu",
  data() {
    return {
      active: '/',
      ishow: true,
      userimae: "",
      url: "",
      username: "",
      filelist: [],
      dialogVisible: false,
      password: "",
      newpassword:"",
      codepassword:"",
      issuperadmin:false



    }
  },
  methods: {
    qr(){
       if (this.password!=""&& this.newpassword!=""&& this.codepassword!=""){
         requ.post("/user/admin/updatepassword",{
            uid:localStorage.getItem("uid"),
           password:this.password,
           newpassword:this.newpassword,
           codepassword:this.codepassword
         }).then(res=>{
            if (res.data.state=="修改成功"){
              ElMessage({
                message: '修改成功',
                type: 'success',
              })
              this.dialogVisible=false

            }
         })

       }else {
         ElMessageBox.alert('所有空不能为空', '警告', {

           confirmButtonText: 'OK',
         })

       }
    },
    updatepassword() {
      this.dialogVisible = true
    },
    change(file, filelist) {
      this.filelist = [filelist[filelist.length - 1]]
      ElMessageBox.confirm("您确定上传吗？", "确定上传？", {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$nextTick(() => {
          this.$refs.upload.submit()
        })
        ElMessage({
          type: 'success',
          message: '正在上传.......',
        })

      }).catch(() => {
        ElMessage({
          type: 'info',
          message: '上传取消^-^',
        })
        this.filelist = []
      })


    },
    httprequ(param) {
      let fileobj = param.file
      let fd = new FormData()
      fd.append("file", fileobj)
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }
      requ.post("/file/upload", fd, config).then(res => {
        requ.get("/user/admin/updateuserimage?uid="+localStorage.getItem("uid")+"&uuid="+res.data.uuid).then(res=>{
          this.filelist=[]
        })
      })

    },
    quit() {
      localStorage.removeItem("token")
      location.reload()

    }
  },
  mounted() {
    this.url = requ.getUri()


  },

  watch: {
    '$route'(newVal) {

      if (newVal.fullPath == "/login") {
        this.ishow = false

        // let  me = document.getElementById("me")
        //  me.style.width="0px"


      } else {
        this.userimae = localStorage.getItem("userimage")
        this.username = localStorage.getItem("username")
        this.ishow = true
         if (this.username=="superadmin"){
           this.issuperadmin=true
         }
      }

    },
    // ishow(){
    //   if (this.ishow==false){
    //     let  view =document.getElementById("view")
    //     view.style.width="100vw"
    //   }
    //
    // }


  }

}
</script>

<style scoped>

</style>